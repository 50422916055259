// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  popupHidePopups: 'popup:hidePopups', // CRO-475
  paymentFieldNoScaling: 'payment:fieldNoScaling', // CRO-558
  popupAlreadyTriedThis: 'popup:alreadytriedthis', // CRO-408
  mainEstimatedDelivery: 'main:estimatedDelivery', // CRO-477
  quizCopyUpdate: 'quiz:copyUpdate', // CRO-481
  selectSection: 'recommendations:selectSection', // CRO-463
  spAddToQueuePopupRedesign: 'sp:addToQueuePopupRedesign', // CRO-535
  subscriptionBannerRedesign: 'subscription:bannerRedesign', // CRO-506
  spFirstStepNotes: 'sp:firstStepNotes', // CRO-526
  spThirdStepCards: 'sp:thirdStepCards', // CRO-544
  homeBrandsRowRedesign: 'home:brandsRowRedesign', // CRO-575
  homeIngredientsRedesign: 'home:ingredientsRedesign', // CRO-508
  popupFirstTimeVisitModalFrequency: 'popup:firstTimeVisitModalFrequency', // CRO-557
  popupFirstTimeVisitModalNotLoggedIn: 'popup:firstTimeVisitModalNotLoggedIn', // CRO-557
  pcPremiumLabel: 'pc:premiumLabel', // CRo-462
  // PF tests
  constructorRecommendations: 'constructorRecommendations', // PF-2319
  aiSummaryReview: 'aiSummaryReview', // PF-2847
  registerFieldNoScaling: 'register:fieldNoScaling', // CRO-518
  // EP tests
  miniBottlesMainImageChange: '20ml:mainImageChange', // EP-3069
  miniBottlesOnFullSize: '20ml:miniBottlesOnFullSize', // EP-3095
  rateAndReviewPopup: '20ml:rateAndReviewPopup', // EP-3128
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
